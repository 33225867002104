import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueHead from 'vue-head';
import VuePageTransition from 'vue-page-transition';
import Icon from 'vue-awesome/components/Icon';
import Link from '@/components/Link';
import VueAnalytics from 'vue-analytics';

import './assets/lucid.scss';
import 'vue-awesome/icons';


Vue.use(VueHead);
Vue.use(VuePageTransition);

Vue.use(VueAnalytics, {
    id: 'UA-11784368-1',
    router
  });

Vue.config.productionTip = false;
Vue.component('icon', Icon);
Vue.component('page-link', Link);


router.beforeEach((to, from, next) => {
    router.app.$emit('close-menu');

    next();
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
