<template>
    <div id="menu">
        <div id="menu-background" @click="closeMenu"> </div>
        <div id="menu-close" @click="closeMenu"><icon name="times-circle" scale="2"/></div>
        <ul>
            <li><page-link to="about" label="About"/></li>
            <li><page-link to="services" label="Services"/></li>
            <li><page-link to="contact" label="Contact"/></li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'navigation',

        data() {
            return {
                servicesActive: false
            }
        },

        methods: {
            closeMenu(){
                this.$root.$emit('close-menu');
            }
        }
    }
</script>

<style lang="scss" scoped>

    ul {
        list-style-type: none;
    }

    #menu {
        position: relative;

        color: white;
        margin: 0;
        
        font-size: 1.5em;

        @include device('desktop'){
            margin-top: 5em;
            font-size: 1.25em;
        }

        #menu-close {
            position: absolute;
            top: 20px;
            left: 20px;
        }
        
        li {
            margin-bottom: 3em;

            a, a:visited {
                color: color('primary');
                text-decoration: none;
            }

            .submenu {
                li {
                    font-size: 0.8em;
                    margin: 1em 0;
                }
            }
        }
    }
</style>