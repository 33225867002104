<template>
    <div id="app">
        <div id="header">
            <div>
                <div id="menu-toggle">
                    <icon name="bars" scale="2" @click="menuActive=true"/>
                </div>
            </div>
            <div id="wordmark" @click="$router.push({name: 'about'})">
                <lucid-wordmark /> 
            </div>
        </div>
        <div id="app-content">
            <navigation :class="{active: menuActive}"/>

            <div id="main-content">
                <vue-page-transition name="fade-in-right">
                    <router-view id="view"/>
                </vue-page-transition>
            </div>
        </div>
    </div>
</template>

<script>
    import LucidQs from '@/assets/lucidqs.svg';
    import Navigation from '@/components/Navigation'

    export default {
        name: 'app',

        components: {
            'lucid-wordmark': LucidQs,
            Navigation
        },

        data() {
            return {
                menuActive: false
            }
        },

        created(){
            this.$root.$on('close-menu', () => { console.log('closing menu'); this.menuActive = false; console.log(this.menuActive)});
        }
    }
</script>

<style lang="scss">
    #app {
        position: relative;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        padding: 1em;
        text-align: left;
        min-height: 100%;

        @include device('desktop'){
            padding-top: 5em;
        }

        #header {
            margin: 0 auto;


            #menu-toggle {
                color: white;
                display: inline-block;
                cursor: pointer;
                margin-bottom: 1em;
            }

            @include device('desktop'){
                max-width: 800px;
                
                #menu-toggle {
                    display: none;
                }
            }

            #wordmark {
                width: 200px;

                @include device('desktop'){
                    margin-left: 50%;
                    width: 260px;
                }
                //margin-bottom: 3em;
                display: inline-block;
                cursor: pointer;
                
                svg {
                    fill: color('primary');
                }
            }
        }
    }

    #app-content {
        position: relative;
        max-width: 1000px;
        min-height: 80vh;
        margin: 0 auto;
        overflow: hidden;

        #main-content {
            color: color('primary');

            @include device('desktop'){
                float: right;
                width: 50%;
                padding: 0;
            }

            #view {
                color: white;
            }
        }

        #menu-background {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            z-index: -10;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.5);
        }
        #menu {
            display: block;
            position: fixed;
            top: 0;
            left: -300px;
            background-color: #020E0D;
            z-index: 100;
            margin: 0;
            height: 500px;
            border-bottom-right-radius: 30px;
            width: 260px;
            padding-top: 5em;
            transition-duration: 0.25s;
            box-shadow: 0 0 10px #000;

            &.active {
                left: 0;

                #menu-background {
                    display: block;
                }
            }

            @include device('desktop'){
                background-color: transparent;
                position: relative;
                width: 50%;
                display: inline-block;
                padding-top: 4em;
                box-shadow: none;
                left: auto;
                float: left;

                #menu-close {
                    display: none;
                }
            }
        }
    }

    a, a:visited {
        color: white;
    }
</style>
