<template>
	<div class="link" :class="{disabled: isDisabled, enabled: !isDisabled, inline: inline, active: isActive}" @click="!isDisabled && (to ? $router.push({ name: to, params: params, query: query }).catch(err => {}) : $emit('click'))" @keydown="keydown">
		<span class="icon" v-if="icon"><icon :name="icon" :scale="scale"/></span>
		<span class="label">{{ label }}</span>
        <div class="body" v-if="isDisabled">
            <slot/>
        </div>
	</div>
</template>


<script>
    import _ from 'lodash';
    
	export default {
		name: 'page-link',

		props: [
            'label',
			'disabled', 
			'to', 
			'params', 
			'icon', 
			'inline', 
            'scale',
            'query'
		],

        methods: {
            keydown(e) {
                this.$emit('keydown', e);
            }
        },

		computed: {
			isDisabled() {
                return this.disabled || (this.$route.name == this.to && this.params == null);
            },
            
            isActive() {
                return this.params == null && (this.$route.name == this.to || (_.has(this.$route, 'meta.root') && this.$route.meta.root == this.to));
            }
		}
    }
</script>

<style scoped lang="scss">

	.link {
        position: relative;
        cursor: pointer;
        color: color('primary');
        transition-duration: 0.5s;
        user-select: none;
        
        &.active {
            color: yellow;
            font-weight: bold;

            &:hover {
                color: yellow;
            }
        }

        .icon {
            display: inline-block;
            position: relative;
            height: 100%;
            vertical-align: middle;
            margin-right: 0.5em;
		}
    }
	
</style>