<template>
    <div class="home">
        home
    </div>
</template>

<script>
    export default {
        name: 'home',
    }
</script>
